import { environment as defaultEnv } from './environment.default';
import { Environment } from './Type';

export const environment: Environment = {
  ...defaultEnv,

  debug: false,
  production: true,
  name: 'production',

  api: {
    protocol: 'https',
    host: 'api.is.menton.sk',
  },
};
